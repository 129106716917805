.MuiInputBase-input {
  /* color: #676767 !important; */
  color: #222d4b !important;
}

label.MuiInputLabel-outlined:not([data-shrink="true"]) {
  transform: translate(14px, 12px) scale(1) !important;
}
label.Mui-focused:not([data-shrink="true"]) {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #d3e0f3 !important;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 14px 14px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl {
  height: 42px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  height: 42px;
}
