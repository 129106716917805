* {
  font-family: Muli;
}
body {
  overflow-x: hidden;
}
.wrapper {
  min-height: calc(100vh - 313px);
  @media (max-width: 768px) {
    min-height: 0;
  }
}

// remove hight chat search bar
.highcharts-range-selector-group,
.highcharts-scrollbar {
  display: none !important;
}

// editor
.height-to-content .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
  height: fit-content !important;
}

#simple-tabpanel-0,
#simple-tabpanel-1,
#simple-tabpanel-2,
#simple-tabpanel-3 {
  padding: 0 !important;
}

input[type="password"]:not(.browser-default).password-error,
input[type="number"]:not(.browser-default).pay-error {
  border-bottom: 1px solid red !important;
  box-shadow: 0 1px 0 0 red !important;
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  position: absolute;
  top: 0;
  color: rgb(196, 196, 196);
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #ae8a4d !important;
}

.calendar-container {
  width: 100%;
  height: 233px;
  width: 273px;
  overflow: hidden;
  //nav block
  .react-calendar__navigation {
    //year back
    .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
    .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
      display: none;
    }

    //next month
    .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
    .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
      width: 24px;
      height: 24px;
      background: #f7f8fc;
      border-radius: 50%;
      border: none;
      margin: 0 0 19px 12px;
      outline: none;
      position: relative;
    }

    //month name
    .react-calendar__navigation__label {
      border: none;
      order: -1;
      height: 18px;
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: #181818;
      padding: 0;
      width: fit-content;
      text-align: left;
      margin-top: 4px;
      background: transparent;
    }
  }

  //numbers block

  //day names
  .react-calendar__month-view__weekdays {
    flex-basis: auto;
    justify-content: space-between;
    .react-calendar__month-view__weekdays__weekday {
      flex-basis: auto !important;
      height: 20px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 135%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      outline: none;
      max-width: 100px !important;
      width: 20px !important;
      color: rgba(24, 24, 24, 0.6);
      margin-right: 17px;
      &:nth-child(7) {
        margin-right: 0;
      }
      > abbr[title] {
        text-decoration: none;
      }
    }
  }

  //days numbers
  .react-calendar__month-view__days {
    height: 140px;
    align-items: flex-start;
    justify-content: space-between;
    .react-calendar__tile.react-calendar__month-view__days__day,
    > button {
      outline: none;
      max-width: 100px !important;
      flex-basis: auto !important;
      width: 20px !important;
      height: 20px;
      background: transparent;
      border-radius: 50%;
      border: none;
      margin: 0 0 12px 0;
      font-family: Muli;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 17px;
      text-align: center;
      color: #181818;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 17px;
      padding: 0 !important;
      &:nth-child(7) {
        margin-right: 0;
      }
      &:nth-child(14) {
        margin-right: 0;
      }
      &:nth-child(21) {
        margin-right: 0;
      }
      &:nth-child(28) {
        margin-right: 0;
      }
      &:nth-child(35) {
        margin-right: 0;
      }
    }
    > button.react-calendar__tile--now.react-calendar__tile--active {
      background: transparent;
      position: relative;
      color: #fff;
    }
  }
}

#globalModal500Error {
  display: flex;
  justify-content: center;
  align-self: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
}
#sun-editor-portal {
  border: none;
}

//adaptive sun-editor header
@media screen and (max-width: 1450px) {
  .kAjCwY .se-toolbar.sun-editor-common ul.se-menu-list > li:first-child {
    margin-right: 10px !important;
    margin-left: 5px !important;
  }
  .kAjCwY .se-toolbar.sun-editor-common ul.se-menu-list > li:nth-child(3n) {
    margin-right: 10px !important;
  }
  .kAjCwY .se-toolbar.sun-editor-common ul.se-menu-list > li:last-child {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 1326px) {
  .kAjCwY .se-toolbar.sun-editor-common ul.se-menu-list > li > .se-btn.se-btn-select.se-btn-tool-format.se-tooltip {
    width: 175px;
  }
}

@media screen and (max-width: 1180px) {
  .kAjCwY .se-toolbar.sun-editor-common ul.se-menu-list > li > .se-btn.se-btn-select.se-btn-tool-format.se-tooltip {
    width: 100px;
  }
  .kAjCwY .se-toolbar.sun-editor-common ul.se-menu-list > li > .se-btn {
    width: 30px;
  }
}
svg.flag {
  width: 20px;
  height: 18px;
  margin-right: 5px;
}
svg.flag-small {
  width: 20px;
  height: 10px;
  margin-right: 5px;
}
